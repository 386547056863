import React from "react"
import Layout from "../Layout"
import { Vacancy } from "../components/rings/Vacancy"
import { BooksAndWebsites } from "../components/rings/BooksAndWebsites"
import { useStaticQuery, graphql } from "gatsby"
import { Seo } from "../components/Seo"
export default function Extra() {
  const extraData = useStaticQuery(graphql`
    query extra {
      sanityVacancy(_id: { eq: "4fe8b9b3-7d61-44d5-adc7-405f48ae2bd0" }) {
        isShown
        titel
        _rawLongMessage
      }
      allSanityBook {
        nodes {
          url
          titel
          isShown
        }
      }
      allSanityHyperlink {
        nodes {
          url
          titel
          isShown
        }
      }
      sanityTerms(_id: { eq: "f175b203-c62b-44b1-bd8b-0c65ccbfce04" }) {
        _id
        titel
        _rawLongMessage
        isShown
      }
    }
  `)

  return (
    <Layout>
      <Seo urlLoc="https://www.onehappyhound.be/extra" />
      {extraData && extraData.sanityVacancy && (
        <Vacancy vacancy={extraData.sanityVacancy} />
      )}

      {extraData && extraData.allSanityBook && extraData.allSanityHyperlink && (
        <BooksAndWebsites
          books={extraData.allSanityBook}
          hyperlinks={extraData.allSanityHyperlink}
        />
      )}
    </Layout>
  )
}
