import React from "react"
import styled from "styled-components"
import { colors, size, mq, fontFamily } from "../../global.style"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBook, faGlobeEurope } from "@fortawesome/pro-solid-svg-icons"

export const BooksAndWebsites = ({ books, hyperlinks }) => {
  return (
    <Wrapper>
      <Books>
        <Title>Interessante Boeken</Title>
        <BookList>
          {books.nodes.map(book => (
            <Book>
              <BookLink href={book.url} target="_blank" rel="noopener">
                <FontAwesomeIconStyled key={`key_${book.id}`} icon={faBook} />
                <span>{book.titel}</span>
              </BookLink>
            </Book>
          ))}
        </BookList>
      </Books>
      <Links>
        <TitleWhite>Relevante Links</TitleWhite>
        <BookList>
          {hyperlinks.nodes.map(link => (
            <Book>
              <BookLinkWhite href={link.url} target="_blank" rel="noopener">
                <FontAwesomeIconStyled
                  key={`key_${link.id}`}
                  icon={faGlobeEurope}
                />
                <span>{link.titel}</span>
              </BookLinkWhite>
            </Book>
          ))}
        </BookList>
      </Links>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${mq.minWidth(size.portrait)} {
    flex-direction: row;
  }
`

const Books = styled.div`
  background-color: #f2f2f2;
  padding: 1.5rem;

  ${mq.minWidth(size.laptop)} {
    padding: 5rem;
  }

  ${mq.minWidth(size.portrait)} {
    width: 50%;
  }
`

const Links = styled.div`
  background-color: ${colors.secLight};
  padding: 1.5rem;
  ${mq.minWidth(size.portrait)} {
    width: 50%;
  }

  ${mq.minWidth(size.laptop)} {
    padding: 5rem;
  }
`

const Title = styled.h2`
  color: ${colors.text};
  ${fontFamily("OpenSansExtraBold")};
  line-height: 1;
  margin: 0;
  margin-bottom: 1.2rem;

  ${mq.minWidth(size.laptop)} {
    margin-bottom: 2rem;
    font-size: 2rem;
  }
`

const TitleWhite = styled(Title)`
  color: #fff;
`

const BookList = styled.ul`
  padding: 0;
  margin: 0;
`

const Book = styled.li`
  list-style-type: none;
  margin-bottom: 0.5rem;
`

const BookLink = styled.a`
  color: #000;
  text-decoration: none;
  ${fontFamily("OpenSansSemiBold")};
  font-size: 0.9rem;
  line-height: 1.6;
  display: flex;
  align-items: center;

  &:hover {
    span {
      text-decoration: underline;
    }
  }

  strong {
    ${fontFamily("OpenSansExtraBold")};
  }

  ${mq.minWidth("1260px")} {
    font-size: 1.125rem;
    margin-bottom: 1rem;
  }
`

const BookLinkWhite = styled(BookLink)`
  color: #fff;
`

const FontAwesomeIconStyled = styled(FontAwesomeIcon)`
  margin-right: 0.5rem;
`
